import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
import List from 'components/mdx/list';
import Li from 'components/mdx/li';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "habit-tracker-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#habit-tracker-app",
        "aria-label": "habit tracker app permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Habit Tracker App`}</h1>
    <p><strong parentName="p">{`Habit Tracker`}</strong>{` is my final project for
`}<a parentName="p" {...{
        "href": "https://www.edx.org/course/cs50s-introduction-to-computer-science"
      }}>{`Harvard's CS50 course`}</a>{`.
It's built as `}<strong parentName="p">{`MVP`}</strong>{` (minimal viable product) and it's core functionality is to
track user's habits.`}</p>
    <h2 {...{
      "id": "video-presentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#video-presentation",
        "aria-label": "video presentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Video presentation`}</h2>
    <iframe {...{
      "width": "100%",
      "height": 315,
      "src": "https://www.youtube-nocookie.com/embed/zIr_d1ZsIGQ?rel=0",
      "frameBorder": "0",
      "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
      "allowFullScreen": true
    }}></iframe>
    <h2 {...{
      "id": "already-implemented-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#already-implemented-features",
        "aria-label": "already implemented features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Already implemented features`}</h2>
    <List mdxType="List">
  <Li check mdxType="Li">Adding, editing and deleting habits</Li>
  <Li check mdxType="Li">Marking habits as completed, failed or skipped</Li>
  <Li check mdxType="Li">Visualization of user performance</Li>
  <Li check mdxType="Li">Customizing app's theme</Li>
  <Li check mdxType="Li">
    Changing the language: <code>EN|ES|PL</code>
  </Li>
    </List>
    <h2 {...{
      "id": "technologies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#technologies",
        "aria-label": "technologies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Technologies`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`React`}</strong>{` - my favorite library for building interactive user interfaces.`}</li>
      <li parentName="ul"><strong parentName="li">{`React Query`}</strong>{` — fetching, caching and updating asynchronous state. Also, I
could significantly improve performance by implementing `}<strong parentName="li">{`optimistic
updates`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`React Router`}</strong>{` — routing but also it was an opportunity to check out the
newest React Router v6 that is still in beta.`}</li>
      <li parentName="ul"><strong parentName="li">{`React Hook Form`}</strong>{` — forms and validation.`}</li>
      <li parentName="ul"><strong parentName="li">{`Material UI`}</strong>{` — my favourite user interface library — it's easy to use and
implement and it's just so much easier to make the app mobile friendly.`}</li>
      <li parentName="ul"><strong parentName="li">{`Firebase`}</strong>{` - `}<strong parentName="li">{`Authentication`}</strong>{`, `}<strong parentName="li">{`Realtime Database`}</strong>{` and `}<strong parentName="li">{`Hosting`}</strong>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "challenges",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#challenges",
        "aria-label": "challenges permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Challenges`}</h2>
    <p>{`I learned a lot while building the project and for sure I'm going to learn a lot
more while maintaining it. That's why I want to keep track of the challenges
that I've had along the way so that I can reference them in the future.`}</p>
    <h3 {...{
      "id": "database-and-data-structure",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#database-and-data-structure",
        "aria-label": "database and data structure permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Database and data structure`}</h3>
    <p>{`How should I store habit's completion state for each day? Should each habit have
an array with the dates when it was performed or should I store dates and each
date would keep track of the habits that where performed on that day?`}</p>
    <p>{`I tried to structure the data so that it is saved and retrieved as easily as
possible. To do so I've been following
`}<a parentName="p" {...{
        "href": "https://firebase.google.com/docs/database/web/structure-data"
      }}>{`Firebase guidelines`}</a>{`
and in the end came up with the following data structure:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "habits": {
    "user-one": {
      "habit-one": {
        "name": "Reading",
        "description": "Read a book for at least 30 min in the morning",
        "frequency": [0, 1, 2, 3, 4]
      }
    }
  },
  "checkmarks": {
    "user-one": {
      "checkmark-id": {
        "habitId": "habit-one",
        "date": "2020-11-11",
        "value": "completed"
      }
    }
  },
  "users": {
    "user-one": {
      "locale": {
        "code": "en-GB"
      },
      "theme": {
        "primaryColor": "blue",
        "secondaryColor": "red",
        "dark": true
      },
      "performanceGoal": 80
    }
  }
}
`}</code></pre>
    <h3 {...{
      "id": "authentication-layer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#authentication-layer",
        "aria-label": "authentication layer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Authentication Layer`}</h3>
    <p>{`For quite some time I was using Private and Public routes to prevent an
authenticated user from accessing the parts of the app available only for logged
in user. It was fine but I wanted to use a different layout for authenticated
users (additional sidebar on the left).`}</p>
    <p>{`I found the perfect solution in a
`}<a parentName="p" {...{
        "href": "https://kentcdodds.com/blog/authentication-in-react-applications"
      }}>{`blog post by Kent C. Dodds`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function App() {
  const user = useUser()
  return user ? <AuthenticatedApp /> : <UnauthenticatedApp />
}
`}</code></pre>
    <h3 {...{
      "id": "localization-and-language",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#localization-and-language",
        "aria-label": "localization and language permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Localization and language`}</h3>
    <p>{`I've never before implemented this in an app and I really wanted to give it a
try. My main goal was to give the user an option to change their locale and
language. Although this goal was achieved, the solution is far from ideal. First
of all, I think that it would be better to split these two layers. For example
in YouTube one can open settings and change either Language or Location.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      